/* Url Keys */
export const urlconstants = {
    login: '/api/login',
    getUserInfo: '/api/getUserInfo',
    forgotpassword: '/api/sendmail',
    resetpassword: '/api/generate-password?token=',
    checkToken: '/api/checkToken?token=',

    loadDropdownList: '/api/usersearchdata',
    loadAdvSearchData: '',
    campaignsforcalendar: '/api/campaignsforcalendar',
    tacticsview: '/api/tacticsview',
    updatetacticsendDate: '/api/updatetacticsendDate',
    getalldropdowns: '/api/usersearchdata',
    tacticDetails: '/api/tacticDetails',
    tacticslistcampaignsview: '/api/tacticslistcampaignsview',
    tacticDetailDropdownValues: '/api/mapvalues',
    fetchCampaignSliderDetails: '/api/fetchCampaignSliderDetails',
    fetchpresetFilters: '/api/getpresetfilters',
    share: '/api/share',
    addPresetFilter: '/api/addpresetfilter',
    deletePresetFilter: '/api/deletepresetfilter',

    loadAllPrograms: '/api/allprogramdetails',
    getUserData: '/api/getuser',
    loadCamps: '/api/getcampaignsbyprogramidanduserinfo',
    loadTactics: '/api/gettacticbyprogramid',
    getTacticsByProgramID: '/api/gettacticbyprogramid',
    getTacticByCampaignID: '/api/gettacticbycampaignid',
    getTacticByTacticID: '/api/gettacticbytacticid',
    getprogrambyprogramid: '/api/getprogrambyprogramid',
    getCampaignsByProgramIDandUserInfo: '/api/getcampaignsbyprogramidanduserinfo',
    getCampaignByCampaignID: '/api/getcampaignbycampaignid',
    deleteProgram: '/api/deleteprogram',
    deleteCampaign: '/api/deletecampaign',
    deleteTactic: '/api/deletetactic',
    loadAllData: '/api/getall',
    getDraftsData: '/api/drafts',
    saveTacticDetails: '/api/tactic',
    getAllTacticDropdownValues: '/api/mapvalues',
    getTacticDetailsMapping: '/api/tacticDetailsMapping',
    getWaveDetailByTacticId: '/api/getwavesbytacticid',
    deleteWaveDetailData: '/api/deletewave',
    saveWaveDetailData: '/api/tacticwave',
    getExposureDetail: '/api/exposureinwaves',
    addProgram: '/api/program',
    gettacticsenddate: '/api/gettacticsenddate',

    saveExposureDetail: '/api/exposuredetails',
    saveNewCampaign: '/api/campaign',

    loadAllTilesData: '/api/datacount',


    deleteProgramData: '/api/deleteprogram',
    deleteCampaignData: '/api/deletecampaign',
    deleteTacticData: '/api/deletetactic',
    deleteWaveData: '/api/deletewave',

    /* =================== Admin API =================================== */

    getTileCounts: '/api/adminTilesCount',
    updateRequestStatus: '/api/updateRequestStatus',
    getAllRequests: '/api/fetchAllRequests',
    getAllUserRequests: '/api/allUserRequests',
    rejectRequest: '/api/reject-request',
    getAllUsers: '/api/usersArray',
    getAllLovs: '/api/lovs',

    activateDeactiveUser: '/api/users',
    getUserDetails: '/api/users',
    addEditUser: '/api/adduser',
    setDefaultPassword: '/api/setDefaultPassword',
    createUserEditRequest: '/api/userEditRequests',
    createUserAddRequest: '/api/userAddRequests',
    allUsers: '/api/users',
    newRequestCloseNotification: '/api/request-complete',
    triggerEditUserEmailNotification: '/api/edit-user',
    triggerDeactivateUserEmailNotification: '/api/deactivate-user',
    triggerActivateUserEmailNotification: '/api/activate-user',
    triggerAddUserEmailNotification: '/api/create-user',
    getBulkUploadPreference: '/api/loadExportConfig',
    saveBulkUploadPreference: '/api/saveExportConfig',
    stopDataExport: '/api/stopExport',
    /** ===============  LOVs API ====================== */
    addLovs: '/api/addLov',
    editLovs: '/api/editLov',
    deleteLov: '/api/deleteLov',
    getAllLovRequests: '/api/fetchLOVRequests',
    createLovEditRequest: '/api/lovEditRequests',
    createLovAddRequest: '/api/lovAddRequests',

    /**  =============== New REquest Notifications ============= */
    newRequestNotification: '/api/new-request',
    getCampaignsWithProgramDetails: '/api/getAllCampaignsWithProgramDetails',
    miscAddRequest: '/api/miscAddRequest',
    allMiscRequests: '/api/allMiscRequest',
    allAppprovedMisc: '/api/allApprovedMisc',
    getExtracts: '/api/extract',

    ///////////////////////////////////////// ======================= //////////////////////////
    getExpCodesInTacticId: '/api/getExpCodesInTacticId',
    miscApproveRequest: '/api/approvebulkupload',
    addWaveDetails: '/api/addwavedetails',
    addwaveofferdetails: '/api/addwaveofferdetails',
    getNotifications: '/api/getNotifications',
    deleteNotification: '/api/deleteNotification',
    getNotificationCount: '/api/getNotificationCount',
    setSeenNotifications: '/api/setSeenNotifications',

    /** ===============  CMAPS API ====================== */
    getFilterValues: '/api/getFilterData',
    getGridData: '/api/getGridData',
    technicalTags: '/api/getTechnicalTags',
    derivedTags: '/api/getDerivedTags',
    saveDerivedTags: '/api/saveDerivedTags',

    /** ============= Bulk Export ============ */
    queryAllData: '/api/queryAllData',
    downloadExtractData: '/api/downloadExtractData',
    programFilters:'/api/filterprogram',
    getApproveIdSearch:'/api/getApprovedJobId',
    myprogramsPinSequence:'/api/saveSequence',
    getTacticExposureCode:'/api/getTacticExposureCode',

    /** =============== Extract ============== */
    extractTrigger: '/api/runIntegration',
    statuscheck: '/api/statusCheck'
};
